
.caption {
    font-size:1em;
    text-align: center;
}
.title {
    font-size: 2em;
    color: #333;
    text-align: center;
    margin-top: 40px;
    font-weight: bold;
}


body {
    font-family: "Open Sans", sans-serif;
    font-size: 16px;
    color: #333;
    line-height: 1.6;
  }
  
  /* Layout */
  .container {
    max-width: 1024px;
    margin: 0 auto;
    padding: 0 20px;
  }
  
  .hero {
    height: 600px; /* Adjust height as needed */
    background-position: center;
    background-size: cover;
  }
  
  .section {
    padding-top: 80px;
    padding-bottom: 80px;
  }
  
  /* Typography */
  h1, h2, h3, h4, h5, h6 {
    font-family: "Playfair Display", serif;
    font-weight: bold;
    margin-bottom: 20px;
  }
  
  h1 {
    font-size: 36px;
  }
  
  h2 {
    font-size: 30px;
  }
  
  p {
    margin-bottom: 15px;
  }
  
  /* Colors */
  .text-navy {
    color: #24293C;
  }
  
  .text-gray {
    color: #F2F2F2;
  }
  
  .text-gold {
    color: #DAA520;
  }
  
  /* Buttons */
  .btn {
    display: inline-block;
    padding: 10px 20px;
    font-size: 16px;
    text-decoration: none;
    background-color: #24293C;
    color: #fff;
    border: none;
    border-radius: 5px;
    transition: all 0.3s ease-in-out;
  }
  
  .btn:hover {
    background-color: #DAA520;
  }
  
  nav {
    margin-top: 10px; /* Drop down the menu by 10px */
    border-top: 1px solid #ccc; /* Add a thin gray line above the navigation */
    border-bottom: 1px solid #ccc; /* Add a thin gray line below the navigation */
  }
  
  nav ul {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex; /* Make the list items flex containers */
    justify-content: center; /* Center the flex items horizontally */
  }
  
  nav li {
    margin: 0 10px; /* Add some space between each list item */
  }
  
  nav a {
    text-decoration: none;
    color: #333; /* Set the color of the links */
  }
  
  /* Style for the bold text */
  nav span {
    font-weight: bold;
  }